
import dayjs from "dayjs";
import router from "@/router";
import { useRoute } from "vue-router";
import { ElLoading } from "element-plus";
import { useStore } from "@/store/index";
import { ArrowUp } from "@element-plus/icons";
import FormBug from "./components/formBug.vue";
import { ResponseParams } from "@/types/response";
import useRequest from "@/composables/useRequest";
import Drawer from "@/components/drawer/index.vue";
import { Pagation } from "@/composables/usePagation";
import { replaceProductId } from "@/views/iteration/useMixin";
import useMessageTip from "@/composables/useMessageTip";
import useRenderTable from "@/composables/useRenderTable";
import useFetchSearch from "./composables/useFetchSearch";
import WangEditor from "@/components/wangEditor/index.vue";
import FullScreen from "@/components/fullscreen/index.vue";
import { getStaffList } from "@/api/request-modules/common";
import { cloneDeep, assign, map, pickBy, throttle } from "lodash";
import { PRIORITY, REJECTDEALREASON } from "@/utils/contantOptions";
import { BtnArray, SearchForm, SearchArray } from "@/types/interface";
import dialogDemand from "@/businessComponents/demandDialog/index.vue";
import { getIterateList } from "@/api/request-modules/board";
import { defineComponent, reactive, toRefs, ref, onMounted, watch, computed, onDeactivated, onActivated } from "vue";
import { BUG_STATUS, BUG_LEVEL, BUG_TYPE, ENVLIST, setObjStringify, removeIdFromRouter, addDetailIdToRouter } from "@/utils/index";
import { getBugDetail, updateBug, createBug, getBugDynamic, getCreateBugPeople } from "@/api/request-modules/test";
import { getIterationDemand, getBugTaskList, getUseCaseList } from "@/api/request-modules/iteration";
import { ArrowDown, Refresh } from "@element-plus/icons";
import { Iterate } from "@/composables/useBoardBase";
import selectOption from "@/businessComponents/selectOption/index.vue";

export default defineComponent({
  name: "test",
  components: {
    Drawer,
    FormBug,
    ArrowUp,
    FullScreen,
    WangEditor,
    dialogDemand,
    ArrowDown,
    Refresh,
    selectOption
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const pageTableRef = ref<any>();
    const { tipMessage } = useMessageTip();

    interface WangText {
      id: number[];
      name?: string;
      field?: string;
      remark?: string;
      description?: string;
      origin_remark?: string;
    }

    interface StaffData {
      id?: number[];
      field: string;
      staff_name: string;
      staff_no: string;
    }

    interface StaffItem {
      options: { staff_no: string; staff_name: number }[];
      label: string;
    }

    interface NItem {
      value: string | number;
      label: string;
    }

    interface SItem {
      id: string | number;
      name: string;
    }

    //#region 修改指派
    const assignModal = ref(false);
    const assignFormData = reactive({
      bug_id: 0,
      remark: null,
      staff_no: ""
    });
    const statusModal = ref(false);
    const isFullScreen = ref(false);

    const arrow = ref(true);
    const refersh = ref(true);
    // 判断是否进行筛选
    const flag = ref<boolean>(false);
    // 表格多选
    const selectMore: any = ref([]);

    const iterateId = computed(() => store.getters.iterateId);
    const currentIter = computed(() => store.getters.currentIter);
    const curProductId = computed(() => store?.getters?.productId);
    const curProductInfo = computed(() => store?.getters?.productInfo);
    const inIteration = computed(() => router.currentRoute.value.meta.inIteration);

    const hasData = ref(true);
    const onIntertion = () => {
      if (router.currentRoute.value.name === "test2" && !iterateId.value) hasData.value = false;
      else hasData.value = true;
    };
    onIntertion();

    const getStatus = (status: number) => {
      const list = BUG_STATUS.find((v: { value: number; label: string }) => v.value === status);
      return list?.label;
    };
    const getType = (type: number) => {
      const list = BUG_TYPE.find((v: { value: number; label: string }) => v.value === type);
      return list?.label;
    };
    const getTextColor = (status: number) => {
      const list = BUG_STATUS.find((v: { value: number; label: string }) => v.value === status);
      return {
        color: list?.color
      };
    };

    const newDemandLists = ref([]);
    const employeeBasicLists = ref<NItem[]>([]);
    const employeeLists = ref([]);
    const employeeListWorking = ref([]);
    const iterateListData = ref([]);
    const iterateBasicLists = ref<NItem[]>([]);
    const taskLists = ref([]);
    const useCaseList = ref([]);
    const iterateList = ref<SItem[]>([]);
    const iterateBasicList = ref<NItem[]>([]);

    const page = ref(1);
    const pageSize = ref(20);
    const allEmployeeLists = ref([]);

    // 初始化待搜索的表单数据
    const defaultParams = {
      id: undefined,
      env: [],
      type: [],
      level: [],
      status: [],
      name: null,
      end_time: "",
      product_id: "",
      create_time: "",
      create_by: [],
      iteration_id: [],
      staff_no: [] as Array<string>,
      staff_name: [] as Array<string>
    };
    const tableData = reactive({
      list: [],
      total: 0,
      bugType: {
        serious_num: 0,
        unsolved_num: 0,
        unvalidated_num: 0
      }
    });
    const loading = ref(false);
    const currentActive = ref("-1");
    const stopAutoGetData = ref<boolean>(false);
    let temp_rowData = ref<Record<string, any>>({});
    let formParams: any = reactive(cloneDeep(defaultParams));
    const BUG_STATUS_DELETE = BUG_STATUS.slice().splice(1, BUG_STATUS.length);
    const createBugPeopleList = ref();

    // 快捷搜索列表的数据
    const defaultBtnArray: BtnArray[] = [
      { id: "-1", label: "所有", key: "" },
      { id: "0,1,6", label: "未解决", key: "status" },
      { id: "2,5", label: "待验证", key: "status" },
      { id: "4", label: "延期处理", key: "status" },
      { id: "6", label: "我创建的", key: "create_by" },
      { id: "7", label: "指给我的", key: "staff_no" },
      { id: "8", label: "本月", key: "create_time" }
    ];

    const defaultSearchForm: any = [
      {
        type: "input",
        label: "ID",
        key: "id",
        val: "",
        number: "plus"
      },
      {
        type: "input",
        label: "名称",
        key: "name",
        val: ""
      },
      {
        type: "select",
        label: "状态",
        val: [],
        multiple: true,
        key: "status",
        valueKey: ["value", "label"],
        listData: BUG_STATUS_DELETE
      },
      {
        type: "select",
        label: "创建人",
        val: [],
        key: "create_by",
        showRecord: true,
        multiple: true,
        valueKey: ["staff_no", "staff_name"],
        listData: createBugPeopleList
      },
      {
        type: "select",
        label: "指派给",
        val: [],
        key: "staff_no",
        showRecord: true,
        multiple: true,
        valueKey: ["staff_no", "staff_name"],
        listData: employeeListWorking
      },
      {
        type: "select",
        label: "优先级",
        val: [],
        key: "priority",
        multiple: true,
        valueKey: ["id", "value"],
        listData: PRIORITY
      },
      {
        type: "select",
        label: "级别",
        val: [],
        key: "level",
        multiple: true,
        listData: BUG_LEVEL
      },
      {
        type: "select",
        label: "类型",
        multiple: false,
        val: [],
        key: "type",
        listData: BUG_TYPE
      },
      {
        type: "select",
        label: "所属环境",
        multiple: false,
        val: [],
        key: "env",
        valueKey: ["id", "value"],
        listData: ENVLIST
      },
      {
        type: "select",
        label: "关联需求",
        multiple: false,
        val: [],
        key: "demand_id",
        valueKey: ["id", "name"],
        listData: newDemandLists
      },
      {
        type: "select",
        label: "关联迭代",
        multiple: false,
        val: [],
        key: "iteration_id",
        valueKey: ["id", "name"],
        listData: iterateList
      },
      {
        type: "select",
        label: "关联任务",
        multiple: false,
        val: [],
        key: "task_id",
        valueKey: ["id", "name"],
        listData: taskLists
      },
      {
        type: "select",
        label: "关联用例",
        multiple: false,
        val: [],
        key: "test_case_id",
        valueKey: ["id", "name"],
        listData: useCaseList
      },
      {
        type: "daterange",
        label: "创建时间",
        multiple: false,
        val: [],
        key: "create_time",
        listData: []
      }
    ];

    const searchArray: SearchArray = reactive({
      btnArray: defaultBtnArray,
      searchForm: defaultSearchForm
    });

    // 抽屉组件form表单
    const defaultDrawerForm = {
      id: null,
      env: 1,
      type: 1,
      hours: null,
      level: 1,
      cause: null,
      status: 0,
      task_id: null,
      priority: 3,
      demand_id: null,
      test_case_id: null,
      name: "",
      remark: "",
      staff_no: "",
      task_name: "",
      staff_name: "",
      demand_name: "",
      test_case_name: "",
      list: [] as Array<Record<string, any>>,
      attachment: [] as Array<Record<string, any>>,
      iteration_id: "",
      iteration_name: "",
      product_name: curProductInfo.value?.name,
      product_id: curProductInfo.value?.id,
      description: "<p><b>[步骤]</b><br/><br/><br/><b>[结果]<br/></b><br/><br/><b>[期望]<br/></b><br/><br/></p>"
    };
    let drawerForm = ref(cloneDeep(defaultDrawerForm));
    const bugDrawer = ref(false);
    const drawerCount = ref(1);
    const richStatus = ref("view");
    const rich2Status = ref("view");
    const headerTitle = ref("新增需求");

    //基础操作列表数据
    const basicBtn = ref();
    const cascaderPanel = ref();
    // 基础向操作 指派人、来源人状态
    const dialogType = ref(1);
    const dialogDemand = ref(false);
    const basicsOption = ref([
      {
        value: "status",
        label: "状态",
        children: BUG_STATUS_DELETE
      },
      {
        value: "priority",
        label: "优先级",
        children: PRIORITY.map((o) => {
          return { value: o.id, label: o.value };
        })
      },
      {
        value: "level",
        label: "级别",
        children: BUG_LEVEL
      },
      {
        value: "type",
        label: "类型",
        children: BUG_TYPE
      },
      {
        value: "staff_no",
        label: "指派给",
        children: []
      },
      {
        value: "iteration_id",
        label: "迭代",
        children: iterateBasicList
      }
    ]);

    onMounted(() => {
      const { status, detailId } = route.query as Record<string, string>;
      if (status) {
        formParams.status = status?.split(",").map((i) => parseInt(i));
        searchArray.searchForm[1].val = formParams.status;
        currentActive.value = "-10";
        removeIdFromRouter();
      } else if (detailId) {
        // 打开bug详情
        handleShowDetail(parseInt(detailId));
      }
      // 迭代下的bug模块
      if (router.currentRoute.value.meta.inIteration) {
        const item = iterateListData.value.find((item: Iterate) => item.id === iterateId.value);
        if (item) {
          defaultSearchForm[9].listData = [item];
        }
      }
    });

    onDeactivated(() => {
      bugDrawer.value = false;
    });

    // 获取迭代数据
    const getIterationData = () => {
      if (!formParams.product_id) return;
      getIterateList<ResponseParams.ResponseDataSuccess>({
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : 0
      }).then((res) => {
        if (res.code === 200) {
          iterateListData.value = res.data;
          iterateList.value = iterateListData.value;
          iterateBasicLists.value = res.data.map((o: Record<string, any>) => {
            return { value: o.id, label: o.name };
          });
        } else {
          iterateListData.value = [];
          iterateBasicLists.value = [];
        }
      });
    };

    // 获取需求列表
    const getNewDemandData = () => {
      if (!formParams.product_id) return;
      getIterationDemand<ResponseParams.ResponseDataSuccess>({
        product_id: formParams.product_id
      }).then((res: any) => {
        if (res.code === 200) {
          newDemandLists.value = res.data;
        } else {
          newDemandLists.value = [];
        }
      });
    };

    const getCreateBugPeopleList = async (product_id: number) => {
      const data = await getCreateBugPeople({ product_id });
      // @ts-ignore
      if (data.code === 200 && data.data) {
        // @ts-ignore
        createBugPeopleList.value = data.data;
      }
    };

    // 获取任务列表
    const getBugTaskData = () => {
      if (!formParams.product_id) return;
      const obj = {
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : 0
      };
      getBugTaskList<ResponseParams.ResponseDataSuccess>(obj).then((res: any) => {
        if (res.code === 200) {
          taskLists.value = res.data;
        } else {
          taskLists.value = [];
        }
      });
    };

    // 获取用例列表
    const getUseCaseData = () => {
      if (!formParams.product_id) return;
      const obj = {
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : 0
      };
      getUseCaseList<ResponseParams.ResponseDataSuccess>(obj).then((res: any) => {
        if (res.code === 200) {
          useCaseList.value = res.data;
        } else {
          useCaseList.value = [];
        }
      });
    };

    const handleGetSelectList = () => {
      getIterationData();
      getNewDemandData();
      getBugTaskData();
      getUseCaseData();
    };

    const setDefaultIterationId = () => {
      if (inIteration.value && iterateId.value) {
        // 迭代下的bug
        formParams.iteration_id = parseInt(iterateId.value, 10);
        iterateList.value = [
          {
            id: currentIter.value.id,
            name: currentIter.value.name
          }
        ];
        iterateBasicList.value = [
          {
            value: currentIter.value.id,
            label: currentIter.value.name
          }
        ];
      } else {
        // 项目下的bug
        currentActive.value = "-1";
        formParams.iteration_id = "";
        searchArray.searchForm[9].val = "";
        iterateList.value = iterateListData.value;
        iterateBasicList.value = iterateBasicLists.value;
      }
    };

    // 分页以及获取数据
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any) => {
      if (params && !params?.product_id) {
        hasData.value = false;
        return;
      }
      stopAutoGetData.value = flag == undefined ? false : true;
      page.value = (pagationParams && pagationParams.pageIndex) || 1;
      pageSize.value = (pagationParams && pagationParams.pageSize) || 20;
      const { response } = useRequest(useFetchSearch, params || Object.assign(formParams));
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count, serious_num, unsolved_num, unvalidated_num }
      } = await pagation(pagationParams);
      tableData.list = list;
      tableData.total = count;
      tableData.bugType.serious_num = serious_num;
      tableData.bugType.unsolved_num = unsolved_num;
      tableData.bugType.unvalidated_num = unvalidated_num;
    };

    const handleTypeList = (isType: string, params: number[]) => {
      searchArray.searchForm = defaultSearchForm;
      if (isType === "serious") {
        formParams.level = params;
        searchArray.searchForm[5].val = params;
      } else if (isType === "notVerified") {
        formParams.status = params;
        formParams.is_reset = 0;
        searchArray.searchForm[2].val = params;
      } else {
        formParams.status = params;
        formParams.is_reset = 0;
        searchArray.searchForm[2].val = params;
      }
      currentActive.value = "-10";
      handleConditionSearch();
    };

    const handleChangeAssign = (row: Record<string, any>) => {
      assignModal.value = true;
      assignFormData.remark = null;
      assignFormData.bug_id = row.id;
      assignFormData.staff_no = row.staff_no;
    };

    // 导出
    const exportTaskDate = async () => {
      if (!tableData.total) return tipMessage(400, "暂无数据，无法导出");
      setObjStringify(formParams, "/api/tomato/bug/export");
    };

    const getSelectId = () => {
      let ids: number[] = [];
      selectMore.value?.forEach((item: Record<string, any>) => {
        ids.push(item.id);
      });
      return ids;
    };

    const handleSelectionChange = (val: any) => {
      selectMore.value = val;
    };

    // 基础向批量操作
    const handleBasicChange = (val: any) => {
      if (val[0] === "staff_no") {
        dialogType.value = 2;
        dialogDemand.value = true;
      } else {
        const obj: any = {
          id: getSelectId()
        };
        const keyArr = ["status", "priority", "level", "type", "iteration_id"];
        const label = cascaderPanel.value.getCheckedNodes()[0].value;
        obj[val[0]] = label;
        if (keyArr.includes(val[0])) {
          obj[val[0]] = label * 1;
        }
        // 如果是指派人，需要把指派人名字传过去，其它只传id即可
        if (val[0] === "staff_no") {
          const userMsg = employeeBasicLists.value.filter((item) => item.value === label);
          obj.staff_name = userMsg[0].label;
        }
        obj.field = val[0];
        editOrAddBug(obj, "edit");
      }
      // 隐藏基础项
      basicBtn?.value.handleClose();
    };

    const updateVal = (val: string | StaffData) => {
      if (val === "no") {
        // 弹框取消操作
        getData(pageTableRef.value?.getCurrentPage(), true, Object.assign(formParams));
        return;
      }
      if (dialogType.value === 2) {
        let obj = val as StaffData;
        obj.id = getSelectId();
        editOrAddBug(obj, "edit");
      }
      dialogDemand.value = false;
    };

    const handleRefersh = async (n?: number) => {
      flag.value = true;
      page.value = 1;
      refersh.value = !refersh.value;
      if (n) {
        pageTableRef.value?.setCurrentPage();
        page.value = 1;
        await getData({ pageIndex: 1, pageSize: pageSize.value as number }, true, Object.assign(formParams));
        flag.value = false;
        stopAutoGetData.value = false;
      } else {
        handleConditionSearch();
      }
    };

    const handleIterationAllEmployeeList = throttle(() => {
      if (!formParams.product_id) return;
      getStaffList<ResponseParams.ResponseDataSuccess>({
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : undefined,
        is_create: true
      }).then((res) => {
        if (res.code === 200 && res.data) {
          allEmployeeLists.value = res.data;
        } else {
          allEmployeeLists.value = [];
        }
      });
    }, 300);

    const handleIterationEmployeeList = throttle(() => {
      handleIterationAllEmployeeList();
      if (!formParams.product_id) return;
      getStaffList<ResponseParams.ResponseDataSuccess>({
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : undefined,
        is_create: true
      }).then((res) => {
        if (res.code === 200 && res.data) {
          employeeLists.value = res.data;
          const staffItem = res.data[1] as StaffItem;
          employeeBasicLists.value = staffItem?.options.map((o: Record<string, any>) => {
            return { value: o.staff_no, label: o.staff_name };
          });
        } else {
          employeeLists.value = [];
          employeeBasicLists.value = [];
        }
      });
    }, 300);

    const getWorkingStaffList = () => {
      getStaffList<ResponseParams.ResponseDataSuccess>({
        product_id: formParams.product_id,
        iteration_id: inIteration.value ? iterateId.value : undefined
      }).then((res) => {
        if (res.code === 200 && res.data) {
          employeeListWorking.value = res.data;
        } else {
          employeeListWorking.value = [];
        }
      });
    };

    // 搜索菜单下拉箭头
    const showSearchList = () => {
      arrow.value = !arrow.value;
      const contentHeight = document.getElementsByClassName("content")[0] as HTMLDivElement;
      const headerHeight = document.getElementsByClassName("search-header")[0] as HTMLDivElement;
      if (arrow.value) pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 50;
      else pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 160;
    };

    const handleConditionSearch = async () => {
      if (flag.value) {
        pageTableRef.value?.setCurrentPage();
        page.value = 1;
        await getData({ pageIndex: 1, pageSize: pageSize.value as number }, true, Object.assign(formParams));
        flag.value = false;
      } else {
        await getData(pageTableRef.value?.getCurrentPage(), true, Object.assign(formParams));
      }
      stopAutoGetData.value = false;
      setTimeout(() => {
        handleIterationEmployeeList();
      }, 500);
    };

    // 排序
    const sortChange = (e: any) => {
      const { prop, order } = e;
      if (!order) return;
      formParams.sort_name = prop;
      formParams.sort_by = order === "ascending" ? 1 : 2;
      getData(pageTableRef.value?.getCurrentPage(), undefined, formParams);
    };

    // 快捷搜索
    const quickSeacrh = (val: BtnArray) => {
      let lastDay, firstDay;
      const { key, id } = val;
      formParams = cloneDeep(
        assign(defaultParams, {
          product_id: curProductInfo.value?.id,
          iteration_id: inIteration.value ? iterateId.value : undefined
        })
      );
      switch (key) {
        case "status":
          formParams[key] = id?.split(",").map((i) => parseInt(i));
          if (formParams.status === "") {
            formParams.is_reset = 1;
          }
          break;
        case "create_by":
        case "staff_no":
          formParams[key] = store.getters.user.staff_no;
          break;
        case "create_time":
          lastDay = dayjs().endOf("month").format("YYYY-MM-DD");
          firstDay = dayjs().format("YYYY-MM-01");
          formParams[key] = [firstDay, lastDay];
          break;
        default:
          break;
      }
      handleRefersh(1);
    };

    const onCancleSearch = () => {
      searchArray.btnArray = defaultBtnArray;
      searchArray.searchForm = defaultSearchForm;
    };

    // 下拉列表搜索
    const changeSearch = (val: SearchForm) => {
      formParams = cloneDeep(
        assign(defaultParams, {
          product_id: curProductInfo.value?.id,
          iteration_id: inIteration.value ? iterateId.value : undefined
        })
      );
      map(val, (value, key) => {
        switch (key) {
          case "id": // ID
          case "name": // 名称
          case "create_by": // 创建人
          case "staff_no": // 指派给
          case "type": // 类型
          case "create_time": // 创建时间
          case "level": // 级别
          case "env": // 所属环境
          case "iteration_id": // 关联迭代
          case "priority": // 优先级
          case "demand_id": // 关联需求
          case "task_id": // 关联任务
          case "test_case_id": // 关联用例
            // 迭代属性并且在bug下面并且迭代属性对应的值不存在，就使用上面全局的iterateId
            if (key === "iteration_id" && inIteration.value && !value) return;
            formParams[key] = value;
            break;
          case "status": // 状态为空时需要特殊处理下
            formParams[key] = value;
            if (formParams.status === "") {
              formParams.is_reset = 1;
            }
            break;
        }
      });
      handleRefersh();
    };

    // 新增bug
    const handleCreateBug = () => {
      headerTitle.value = "新增BUG";
      richStatus.value = "add";
      rich2Status.value = "add";
      drawerForm.value = cloneDeep(defaultDrawerForm);
      // 产品要求把筛选条件中的迭代带入，所以会有以下代码
      if (inIteration.value) {
        drawerForm.value.iteration_id = currentIter?.value?.id;
        drawerForm.value.iteration_name = currentIter?.value?.name;
      }
      bugDrawer.value = true;
    };

    //#region  修改状态
    const handleChangeStatus = (row: Record<string, any>) => {
      getBugDetail<ResponseParams.ResponseDataSuccess>(row.id).then((res) => {
        if (res.code === 200 && res.data) {
          const { id, status, cause, hours, remark } = res.data;
          temp_rowData.value.id = id;
          temp_rowData.value.status = status;
          temp_rowData.value.cause = cause ? cause : 1;
          temp_rowData.value.hours = hours ? hours : "";
          temp_rowData.value.remark = remark ? remark : "";
          statusModal.value = true;
        }
      });
    };

    const resetDrawerForm = (data: any) => {
      const {
        id,
        env,
        type,
        level,
        cause,
        task_id,
        priority,
        demand_id,
        test_case_id,
        iteration_id,
        name,
        hours,
        remark,
        status,
        staff_no,
        task_name,
        staff_name,
        demand_name,
        product_name,
        test_case_name,
        iteration_name,
        attachment,
        description,
        product_id
      } = data as Record<string, any>;
      // 这里的写法是为了兼容组件显示
      drawerForm.value = data;
      drawerForm.value.id = id ? id : null;
      drawerForm.value.env = env ? env : null;
      drawerForm.value.type = type ? type : null;
      drawerForm.value.level = level ? level : null;
      drawerForm.value.cause = cause ? cause : null;
      drawerForm.value.task_id = task_id ? task_id : null;
      drawerForm.value.priority = priority ? priority : null;
      drawerForm.value.demand_id = demand_id ? demand_id : null;
      drawerForm.value.test_case_id = test_case_id ? test_case_id : null;
      drawerForm.value.iteration_id = iteration_id ? iteration_id : null;
      drawerForm.value.hours = hours;
      drawerForm.value.name = name;
      drawerForm.value.remark = remark;
      drawerForm.value.staff_no = staff_no;
      drawerForm.value.status = status;
      drawerForm.value.task_name = task_name;
      drawerForm.value.staff_name = staff_name;
      drawerForm.value.demand_name = demand_name;
      drawerForm.value.product_name = product_name;
      drawerForm.value.test_case_name = test_case_name;
      drawerForm.value.iteration_name = iteration_name;
      drawerForm.value.attachment = attachment;
      drawerForm.value.description = description;
      drawerForm.value.product_id = product_id;
    };

    // 复制后带参数新增
    const handleCopyList = (row: any) => {
      getBugDetail<ResponseParams.ResponseDataSuccess>(row.id).then((res) => {
        if (res.code === 200 && res.data) {
          resetDrawerForm(res.data);
          drawerForm.value.id = null;
          drawerForm.value.status = 0;
          drawerForm.value.list = [];
          drawerForm.value.name = row.name;
          drawerForm.value.remark = "";
          drawerForm.value.description = "<p><b>[步骤]</b><br/><br/><br/><b>[结果]<br/></b><br/><br/><b>[期望]<br/></b><br/><br/></p>";
          headerTitle.value = "新增BUG";
          richStatus.value = "add";
          rich2Status.value = "add";
          bugDrawer.value = true;
        }
      });
    };

    const updateBugDynamic = (bugId: string | number) => {
      getBugDynamic<ResponseParams.ResponseDataSuccess>(bugId).then((res) => {
        if (res.code === 200 && res.data) {
          drawerForm.value.list = res.data;
        }
      });
    };

    // 查看详情
    const handleShowDetail = (bugId: string | number) => {
      if (!bugId) return;
      Promise.all([getBugDetail<ResponseParams.ResponseDataSuccess>(bugId), getBugDynamic<ResponseParams.ResponseDataSuccess>(bugId)])
        .then((res) => {
          if (res[0].code === 200 && res[0].data) {
            resetDrawerForm(res[0].data);
            headerTitle.value = "查看BUG详情";
            richStatus.value = "view";
            rich2Status.value = "view";
          }
          if (res[1].code === 200 && res[1].data) {
            drawerForm.value.list = res[1].data;
            loading.value = true;
            bugDrawer.value = true;
            addDetailIdToRouter(drawerForm.value.id);
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleBugDetail = (bugId: string | number) => {
      if (!bugId) return;
      updateBugDynamic(bugId);
      getBugDetail<ResponseParams.ResponseDataSuccess>(bugId).then((res) => {
        if (res.code === 200 && res.data) {
          headerTitle.value = "查看BUG详情";
          richStatus.value = "view";
          rich2Status.value = "view";
          bugDrawer.value = true;
        }
      });
      getData(pageTableRef.value.getCurrentPage());
      setTimeout(() => {
        handleIterationEmployeeList();
      }, 500);
    };

    // 抽屉-保存数据
    const onHandle = async (type: "save" | "cancel", currentRt: any, who: string) => {
      if (type === "cancel") {
        bugDrawer.value = false;
        return;
      }

      if (who === "title") {
        const obj: WangText = {
          id: [currentRt.id],
          field: "name",
          name: currentRt.title
        };
        if (currentRt.title.length < 4 || currentRt.title.length > 100) return tipMessage(400, "请输入4-100个字数内的标题");
        drawerForm.value.name = currentRt.title;
        editOrAddBug(obj, "edit");
        return;
      }
      // who存在代表编辑，否则是新增需求
      if (currentRt.id) {
        const obj: WangText = {
          id: [currentRt.id],
          field: ""
        };
        if (currentRt.who === "rich") {
          obj.field = "description";
          obj.description = currentRt.rich;
        } else {
          obj.field = "remark";
          obj.remark = currentRt.rich2;
        }
        await editOrAddBug(obj, currentRt.id ? "edit" : "add");
        // 重新复制支持回显
        if (currentRt.who === "rich") {
          drawerForm.value.description = currentRt.rich;
        } else {
          drawerForm.value.remark = currentRt.rich2;
        }
      } else {
        if (!currentRt.title) return tipMessage(400, "标题不能为空");
        if (currentRt.title.length < 4 || currentRt.title.length > 100) return tipMessage(400, "请输入4-100个字数内的标题");
        drawerForm.value.name = currentRt.title;
        drawerForm.value.description = currentRt.rich;
        drawerForm.value.remark = currentRt.rich2;
        const params = {
          name: drawerForm.value.name,
          description: drawerForm.value.description,
          remark: drawerForm.value.remark,
          attachment: drawerForm.value.attachment,
          type: drawerForm.value.type,
          status: drawerForm.value.status,
          staff_no: drawerForm.value.staff_no,
          level: drawerForm.value.level,
          priority: drawerForm.value.priority,
          env: drawerForm.value.env,
          product_id: drawerForm.value.product_id,
          iteration_id: drawerForm.value.iteration_id,
          demand_id: drawerForm.value.demand_id,
          task_id: drawerForm.value.task_id,
          test_case_id: drawerForm.value.test_case_id
        };
        if (!params.staff_no) {
          return tipMessage(400, "请选择指派人");
        } else {
          editOrAddBug(
            pickBy(params, (val) => val),
            "add"
          );
        }
      }
    };

    // 更新后的下拉列表选项
    const onChangeSelect = (val: string | number, item: Record<string, any>, key?: string) => {
      const params: Record<string, any> = {
        id: [item.id],
        field: key
      };
      if (key === "priority") params.priority = val;
      if (key === "status") params.status = val;
      if (key === "level") params.level = val;
      if (key === "staff_no") {
        const person = employeeBasicLists.value.filter((item) => item.value === val);
        params.staff_no = person[0].value;
        params.staff_name = person[0].label;
      }
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          handleConditionSearch();
          return tipMessage(200, "成功");
        }
      });
    };

    // 修改指定bug的状态
    const onChangePriority = (val: Record<string, any>, item: Record<string, any>) => {
      const params: any = {
        id: [item.id],
        priority: val.id,
        field: "priority"
      };
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          handleConditionSearch();
          return tipMessage(200, "成功");
        }
      });
    };

    const onChangeType = (val: Record<string, any>, item: Record<string, any>, type?: string) => {
      const params: Record<string, any> = {
        id: [item.id]
      };
      if (type === "staff") {
        params.staff_no = val.value;
        params.staff_name = val?.label;
        params.field = "staff_no";
      }
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          handleConditionSearch();
          return tipMessage(200, "成功");
        }
      });
    };

    const onChangeLevel = (val: Record<string, any>, item: Record<string, any>) => {
      const params: any = {
        id: [item.id],
        level: val.id,
        field: "level"
      };
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          handleConditionSearch();
          return tipMessage(200, "成功");
        }
      });
    };

    // 编辑或者新增需求  type: add代表创建需求，edit代表编辑描述或者备注
    const editOrAddBug = async (params: any, type: string) => {
      if (type === "add") {
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        createBug<ResponseParams.ResponseDataSuccess>(params as any)
          .then((res) => {
            if (res.code === 200) {
              bugDrawer.value = false;

              handleConditionSearch();

              return tipMessage(200, "成功");
            }
          })
          .finally(() => {
            loading.close();
          });
      } else {
        await updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
          if (res.code === 200) {
            setTimeout(() => {
              handleConditionSearch();
            }, 500);
            return tipMessage(200, "成功");
          }
        });
      }
    };

    // 修改指派弹窗点击确定
    const handleConfirmChangeAssign = () => {
      const params: any = {
        id: [assignFormData.bug_id],
        remark: assignFormData.remark,
        staff_no: assignFormData.staff_no,
        field: "staff_no"
      };
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          tipMessage(200, "成功");
          getData(pageTableRef.value.getCurrentPage());
          assignModal.value = false;
          handleIterationEmployeeList();
        }
      });
    };

    // 弹窗点击修改bug状态
    const handleConfirmChange = () => {
      const { id, status, cause, hours, remark } = temp_rowData.value;
      let params: any = {
        id: [id],
        status,
        remark,
        field: "status"
      };
      if ([4, 5].includes(status)) {
        params.cause = cause;
      } else if ([2].includes(status)) {
        // const r = /^\+?[0-9][0-9]*$/;
        // if (!r.test(hours)) return tipMessage(400, "请输入大于0的正整数");
        params.hours = parseInt(hours, 10);
        if (params.hours > 999 || params.hours < 0) return tipMessage(400, "请输入0到999的正整数");
      }
      updateBug<ResponseParams.ResponseDataSuccess>(params as any).then((res) => {
        if (res.code === 200) {
          tipMessage(200, "成功");
          getData(pageTableRef.value.getCurrentPage());
          statusModal.value = false;
        }
      });
    };

    let initTimer: ReturnType<typeof setTimeout>;

    const initializeData = () => {
      clearTimeout(initTimer);
      initTimer = setTimeout(() => {
        setDefaultIterationId();
        handleConditionSearch();
        handleGetSelectList();
      }, 400);
    };

    watch(
      () => route.query,
      throttle(() => {
        const { detailId, type, status, where } = route.query as Record<string, string>;
        if (router.currentRoute.value.name === "test" && detailId) {
          setTimeout(() => {
            handleShowDetail(detailId as string);
          }, 300);
          if (where === "workbench") {
            setTimeout(() => {
              // 去掉路由的参数
              router.replace({
                path: route.path,
                query: { ...route.query, iteration_id: undefined, id: undefined, name: undefined, where: undefined }
              });
            }, 700);
          }
        } else {
          if (where === "headerTest" && type === "add") {
            handleCreateBug();
            removeIdFromRouter();
          }
          if (where === "home" && status) {
            formParams.status = status?.split(",").map((i: string) => parseInt(i));
            searchArray.searchForm[1].val = formParams.status;
            currentActive.value = "-10";
          }
        }
      }, 200),
      { immediate: true }
    );

    watch(
      () => iterateId.value,
      (newValue, oldValue) => {
        onIntertion();
        if (newValue == oldValue) return;
        initializeData();
      }
    );

    watch(
      () => curProductId.value,
      (newValue, oldValue) => {
        if (newValue == oldValue) return;
        if (formParams.product_id != newValue) {
          formParams.iteration_id = null;
        }
        formParams.product_id = newValue;
        defaultDrawerForm.product_id = newValue;
        initializeData();
      },
      { immediate: true }
    );

    // 是否是迭代下的bug列表
    watch(
      () => inIteration.value,
      (newValue, oldValue) => {
        if (newValue == oldValue) return;
        // 这里会导致其他页面被影响到，先加个条件判断
        const currentPath = router.currentRoute.value.name!;
        if (currentPath !== "test") return;
        initializeData();
      }
    );

    watch(
      () => bugDrawer.value,
      (newValue) => {
        if (!newValue) {
          drawerCount.value++;
          removeIdFromRouter();
        }
      }
    );

    onActivated(() => {
      onIntertion();
      replaceProductId();
      getData(pageTableRef.value?.getCurrentPage(), true, Object.assign(formParams));
    });

    return {
      getWorkingStaffList,
      curProductId,
      getCreateBugPeopleList,
      employeeListWorking,
      loading,
      page,
      arrow,
      bugDrawer,
      drawerCount,
      refersh,
      iterateId,
      basicBtn,
      taskLists,
      drawerForm,
      richStatus,
      formParams,
      dialogType,
      selectMore,
      rich2Status,
      headerTitle,
      useCaseList,
      searchArray,
      assignModal,
      statusModal,
      basicsOption,
      temp_rowData,
      isFullScreen,
      pageTableRef,
      dialogDemand,
      cascaderPanel,
      employeeLists,
      currentActive,
      assignFormData,
      newDemandLists,
      stopAutoGetData,
      iterateList,
      iterateListData,
      employeeBasicLists,
      iterateBasicList,
      ENVLIST,
      PRIORITY,
      BUG_TYPE,
      BUG_LEVEL,
      BUG_STATUS,
      REJECTDEALREASON,
      BUG_STATUS_DELETE,
      getData,
      getType,
      onHandle,
      getStatus,
      updateVal,
      sortChange,
      quickSeacrh,
      changeSearch,
      onCancleSearch,
      onChangeType,
      getTextColor,
      onChangeLevel,
      handleRefersh,
      showSearchList,
      handleTypeList,
      handleCopyList,
      exportTaskDate,
      handleCreateBug,
      handleBugDetail,
      onChangeSelect,
      onChangePriority,
      handleShowDetail,
      handleBasicChange,
      handleChangeStatus,
      handleChangeAssign,
      handleConfirmChange,
      handleConditionSearch,
      handleSelectionChange,
      handleConfirmChangeAssign,
      ...toRefs(tableData),
      staffNo: store.getters.user.staff_no,
      hasData
    };
  }
});
